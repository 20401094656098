import { v1 as uuid } from 'uuid';
import { useEffect } from 'react';
import { portfolioActions } from '../../../actions';
import { createFilterActions } from '../../../actions/filter.actions';
import { useAppSelector } from '../../../effects/useAppSelector';
import { BwicFilterType } from '../../../types/filters/FilterState';
import { PageConfigType } from '../../../types/page-config/PageConfigType';
import { isRequesting } from '../../../utils';
import { BwicFilters } from '../../filters/BwicFilters';
import { portfolioInitialFilters } from './portfolioInitialFilters';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { PortfolioFilterAlertPopup } from '../portfolio-alert/PortfolioFilterAlertPopup';
import { PortfolioUserConfigFilter } from '../../../types/user-config/UserConfigFilter';
import { AlertOption } from '../../../types/amr-pipeline/enums/AlertOption';
import { useAppDispatch } from '../../../effects/useAppDispatch';
import { FilterManagementForm } from '../../amrPipeline/aggregated/filters-management/types';
import { EmailPreferencesRadioGroup } from '../../profile/tabs/email-preferences/tabs/EmailPreferencesRadioGroup';
import { getSerializedFilter } from '../../../utils/filtering/serializers/bwic/serializeFilter';
import { usePageConfig } from '../../common/pageConfig/usePageConfig';
import { textLabels } from '../../../constants/text-labels';

const filterActions = createFilterActions(BwicFilterType.Portfolio);

export const PortfolioFilters = () => {
    const dispatch = useAppDispatch();

    const {
        filter,
        visibleFilters,
        lastAppliedFilter,
        filterChanged,
        filterModified
    } = useAppSelector(s => s.filters.portfolio);
    const requestState = useAppSelector(s => s.entities.portfolios.requestState);
    const hasPortfolios = useAppSelector(s => !!s.entities.portfolios.items.length);
    const selectedFilterReferenceName = useAppSelector(s => s.filters.portfolio.selectedFilterReferenceName);

    const pageConfig = usePageConfig(PageConfigType.Portfolio, true, BwicFilterType.Portfolio);

    useEffect(() => {
        return () => {
            dispatch(filterActions.resetFilter());
            dispatch(filterActions.resetFilterState());
            dispatch(filterActions.resetFiltersVisibility());
        }
    }, [dispatch])

    const handleApplyFilter = () => {
        dispatch(portfolioActions.advancedFilterBlocked(false));
        dispatch(portfolioActions.applyFilter())
    }

    const renderAlertOptions = (
        state: FilterManagementForm,
        onChange: (nextState: FilterManagementForm) => void) => {
            const bwicAlert = state.bwicAlert ?? AlertOption.Instant;
            const inventoryAlert = state.inventoryAlert ?? AlertOption.Instant;

        return (
            <>
                <EmailPreferencesRadioGroup
                    value={bwicAlert}
                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                    alertOptionInfo={{
                        [AlertOption.Instant]: textLabels.savedFiltersPortfolioBwicInstantAlert
                    }}
                    groupNameIndentifier="single-portfolio-bwic-alert-state"
                    title="BWIC Alerts"
                    onChange={bwicAlert => onChange({ ...state, bwicAlert, inventoryAlert })}
                />
                <EmailPreferencesRadioGroup
                    value={inventoryAlert}
                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                    alertOptionInfo={{
                        [AlertOption.Instant]: textLabels.saveFiltersPortfolioInventoryInstantsAlert
                    }}
                    groupNameIndentifier="single-portfolio-inventory-alert-state"
                    title="Inventory Alerts"
                    onChange={inventoryAlert => onChange({ ...state, bwicAlert, inventoryAlert })}
                />
            </>
        );
    }

    const handleFilterCreate = (form: FilterManagementForm) => {
        const newFilter: PortfolioUserConfigFilter = {
            name: form.name,
            referenceName: uuid(),
            default: form.makeDefault,
            filter: getSerializedFilter(filter),
            dealerInventoryAlertOption: form.inventoryAlert ?? AlertOption.Instant,
            bwicAlertOption: form.bwicAlert ?? AlertOption.Instant,
            issuanceMonitorAlertOption: form.issuanceMonitorAlert ?? AlertOption.Never,
            rollerDeadlineAlertOption: form.rollerDeadlineAlert ?? AlertOption.Never,
            alertOption: AlertOption.Never,
        };

        pageConfig.filterActions.addFilter(newFilter);
    }

    const handleFilterUpdate = (form: FilterManagementForm) => {
        const existingFilter = pageConfig.config?.filters.find(f => f.referenceName === form.referenceName);
        if (!existingFilter) return;

        const updatedFilter = {
            ...existingFilter,
            filter: getSerializedFilter(filter),
            default: form.makeDefault,
            dealerInventoryAlertOption: form.inventoryAlert,
            bwicAlertOption: form.bwicAlert,
            alertOption: AlertOption.Never
        }

        pageConfig.filterActions.updateFilter(updatedFilter)
    }

    return (
        <BwicFilters
            withAlerts={true}
            lastAppliedFilter={lastAppliedFilter}
            filter={filter}
            visibleFilters={visibleFilters}
            filterChanged={filterChanged}
            filterModified={filterModified}
            filterType={BwicFilterType.Portfolio}
            isSearching={isRequesting(requestState)}
            hiddenFilters={portfolioInitialFilters.hiddenFilters}
            withBlockedSearchAndFilter={!hasPortfolios}
            saveFilterVisible={true}
            filterConfigShowEmailNotification={true}
            pageConfigType={PageConfigType.Portfolio}
            selectedFilterReferenceName={selectedFilterReferenceName}
            saveFilterBlockerFeature={SubscriptionFeature.PortfolioSavedFilters}
            advancedFiltersBlockerFeature={SubscriptionFeature.PortfolioAvancedFilters}
            defaultAlertOption={AlertOption.Instant}
            renderFilterAlertsPopup={(filter, onClose) =>
                <PortfolioFilterAlertPopup
                    filter={filter as PortfolioUserConfigFilter}
                    onClose={onClose}
                />
            }
            renderCustomAlertOptions={renderAlertOptions}
            onApply={handleApplyFilter}
            onReset={() => dispatch(portfolioActions.resetFilter())}
            onVisibilityChange={(filterName: string) => dispatch(filterActions.filterVisibilityChange(filterName))}
            onFilterCreate={handleFilterCreate}
            onFilterUpdate={handleFilterUpdate}
        />
    )
}

import { useEffect, useState } from "react";
import { PortfolioUserConfigFilter } from "../../../types/user-config/UserConfigFilter";
import { Popup, PopupBody, PopupFooter } from "../../controls";
import { AlertOption } from "../../../types/email-preferences/EmailPreferences";
import { EmailPreferencesRadioGroup } from "../../profile/tabs/email-preferences/tabs/EmailPreferencesRadioGroup";
import { usePageConfig } from "../../common/pageConfig/usePageConfig";
import { PageConfigType } from "../../../types/page-config/PageConfigType";
import { BwicFilterType } from "../../../types/filters/FilterState";
import { isRequestSuccess, isRequesting } from "../../../utils/request-state.utils";
import { textLabels } from "../../../constants/text-labels";
import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";

interface Props {
    filter: PortfolioUserConfigFilter;
    onClose: () => void;
}

export function PortfolioFilterAlertPopup({ filter, onClose }: Props) {
    const [bwicAlert, setBwicAlert] = useState(filter.bwicAlertOption ?? AlertOption.Never);
    const [inventoryAlert, setInventoryAlert] = useState(filter.dealerInventoryAlertOption ?? AlertOption.Never);
    const [issuanceMonitorAlert, setIssuanceMonitorAlert] = useState(filter.issuanceMonitorAlertOption ?? AlertOption.Never);
    const [rollerDeadlineAlert, setRollerDeadlineAlert] = useState(filter.rollerDeadlineAlertOption ?? AlertOption.Never);


    const { config, updateRequestState, saveAlerts } =
        usePageConfig(
            PageConfigType.Portfolio,
            true,
            BwicFilterType.Portfolio
        );

    const hasChanges =
        bwicAlert !== filter.bwicAlertOption ||
        inventoryAlert !== filter.dealerInventoryAlertOption ||
        issuanceMonitorAlert !== filter.issuanceMonitorAlertOption ||
        rollerDeadlineAlert !== filter.rollerDeadlineAlertOption;


    useEffect(() => {
        if (isRequestSuccess(updateRequestState)) {
            handleClose();
        }
        // eslint-disable-next-line
    }, [updateRequestState])

    const handleSave = () => {
        if (!config?.filters || !hasChanges) {
            return;
        }

        const updatedFilters = config?.filters.map(f => f.referenceName === filter.referenceName
            ? { ...f, bwicAlertOption: bwicAlert, dealerInventoryAlertOption: inventoryAlert, issuanceMonitorAlertOption: issuanceMonitorAlert, rollerDeadlineAlertOption: rollerDeadlineAlert}
            : f);

        saveAlerts(updatedFilters);
    }

    const handleClose = () => {
        if (!isRequesting(updateRequestState)) {
            onClose();
        }
    }

    return (
        <Popup
            modalClass="modal-email-preferences"
            renderInBody
            title={
                <>
                    Portfolio Alerts <span className="name-tag">{filter.name}</span>
                </>
            }
            onClose={handleClose}
        >
            <PopupBody className="notifications-center">
                <EmailPreferencesRadioGroup
                    value={bwicAlert}
                    disabled={isRequesting(updateRequestState)}
                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                    alertOptionInfo={{
                        [AlertOption.Instant]: textLabels.savedFiltersPortfolioBwicInstantAlert,
                    }}
                    groupNameIndentifier="single-portfolio-bwic-alert-state"
                    title="BWIC Alerts"
                    description={textLabels.savedFiltersPortfolioBwicAlertDescription}
                    onChange={setBwicAlert}
                />
                <EmailPreferencesRadioGroup
                    value={inventoryAlert}
                    disabled={isRequesting(updateRequestState)}
                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                    alertOptionInfo={{
                        [AlertOption.Instant]: textLabels.saveFiltersPortfolioInventoryInstantsAlert,
                    }}
                    groupNameIndentifier="single-portfolio-inventory-alert-state"
                    title="Inventory Alerts"
                    description={textLabels.savedFiltersPortfolioInventoryAlertDescription}
                    onChange={setInventoryAlert}
                />
                <EmailPreferencesRadioGroup
                    value={issuanceMonitorAlert}
                    disabled={isRequesting(updateRequestState)}
                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                    alertOptionInfo={{
                        [AlertOption.Instant]: textLabels.savedFiltersPortfolioIssuanceMonitorInstantAlert,
                    }}
                    groupNameIndentifier="single-portfolio-issuance-monitor-alert-state"
                    title="Issuance Monitor"
                    description={textLabels.savedFiltersPortfolioIssuanceMonitorAlertDescription}
                    onChange={setIssuanceMonitorAlert}
                    requiredFeatures={[
                        SubscriptionFeature.IssuanceMonitorFullAccess,
                        SubscriptionFeature.PortfolioAlerts,
                    ]}
                />
                <EmailPreferencesRadioGroup
                    value={rollerDeadlineAlert}
                    disabled={isRequesting(updateRequestState)}
                    alertOptions={[AlertOption.Never, AlertOption.Daily]}
                    alertOptionInfo={{
                        [AlertOption.Daily]: textLabels.savedFiltersPortfolioRollerDeadlineDailyAlert,
                    }}
                    groupNameIndentifier="single-portfolio-roller-deadline-alert-state"
                    title="Roller Deadline"
                    description={textLabels.savedFiltersPortfolioRollerDeadlineAlertDescription}
                    onChange={setRollerDeadlineAlert}
                    requiredFeatures={[
                        SubscriptionFeature.IssuanceMonitorFullAccess,
                        SubscriptionFeature.PortfolioAlerts,
                    ]}
                />
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" disabled={isRequesting(updateRequestState)} onClick={handleClose}>
                    Cancel
                </button>
                <button
                    className="btn btn-main"
                    disabled={!hasChanges || isRequesting(updateRequestState)}
                    onClick={handleSave}
                >
                    Save
                </button>
            </PopupFooter>
        </Popup>
    );
}

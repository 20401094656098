import { ShowFor } from '../../access';
import { roles } from '../../../constants';
import { Checkbox } from '../../controls';
import { TickerButton } from '../../common';
import { PortfolioSecurity } from '../../../types/portfolio/PortfolioSecurity';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { portfolioActions, securityDetailsActions } from '../../../actions';
import { AllToAllArgeementRequiredActionBlocker } from '../../agreements/AllToAllArgeementRequiredActionBlocker';
import { user } from '../../../user';
import { SecurityBwicStatistics } from '../../../types/security/SecurityBwicStatistics';
import { bwicLogActions } from '../../../actions/bwic-log.actions';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface Props {
    isSecuritySelected: boolean;
    security: PortfolioSecurity;
    onSecuritySelect: () => void;
    currentBwic?: SecurityBwicStatistics;
}
export const PortfolioSecurityTicker = ({ isSecuritySelected, security, onSecuritySelect, currentBwic }: Props) => {
    const dispatch = useAppDispatch();
    const isSettlementAgent = user.current()?.isClearingBank;

    const selectedBwic = useSelector((state: AppState) => state.portfolio.selectedBwic);
    const selectedSecurity = useSelector((state: AppState) => state.portfolio.selectedSecurity);
    const securityDetails = useSelector((state: AppState) => state.securityDetails.security);

    const handleTickerButtonClick = (security: PortfolioSecurity) => {
        const canViewBwicRules = currentBwic && (!currentBwic.isParsed || currentBwic.process);
        if (canViewBwicRules) {
            if (
                selectedBwic &&
                selectedBwic.referenceName === currentBwic.referenceName &&
                selectedSecurity?.id === security.id &&
                securityDetails
            ) {
                dispatch(securityDetailsActions.securityDetailsReset());
                dispatch(bwicLogActions.bwicLogReset());
                dispatch(portfolioActions.hideBwicDetails());
            } else {
                dispatch(portfolioActions.showBwicDetails(security, currentBwic));
            }
        } else {
            dispatch(portfolioActions.hideBwicDetails());
        }
    };

    return (
        <>
            <ShowFor roles={[...roles.seller(), roles.BrokerDealerTrader]}>
                <AllToAllArgeementRequiredActionBlocker>
                    {blocked => !blocked && !isSettlementAgent &&
                        <Checkbox
                            checked={isSecuritySelected}
                            onChange={(e) => {
                                e.stopPropagation();
                                onSecuritySelect()
                            }}
                        />}
                </AllToAllArgeementRequiredActionBlocker>
            </ShowFor>
            <TickerButton
                security={security}
                fetchAgencyRatings={true}
                onClick={() => handleTickerButtonClick(security)}
            />
        </>
    )
}

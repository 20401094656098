import { PortfolioSecurity } from '../../../types/portfolio/PortfolioSecurity';
import { portfolioActions } from '../../../actions';
import { SecurityBwicStatistics } from '../../../types/security/SecurityBwicStatistics';
import { BwicRulesButton } from '../../bwic-rules/BwicRulesButtons';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface Props {
    security: PortfolioSecurity;
    currentBwic?: SecurityBwicStatistics;
}

export const PortfolioBwicDetails = ({ security, currentBwic }: Props) => {
    const dispatch = useAppDispatch();

    if (!currentBwic) {
        return null;
    }

    const handleClick = (visible: boolean) => {
        const canViewBwicRules = !currentBwic.isParsed || (currentBwic.process);
        if (canViewBwicRules) {
            if (visible) {
                dispatch(portfolioActions.showBwicDetails(security, currentBwic));
            } else {
                dispatch(portfolioActions.hideBwicDetails());
            }
        }
    };

    return <BwicRulesButton
        id={String(security.id)}
        bidsDueUtc={currentBwic.bidsDueUtc}
        onClick={handleClick}
    />
};

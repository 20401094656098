import * as React from 'react';
import { useSelector } from 'react-redux';
import { RightSideTabPanel } from '../controls/RightSideTabPanel';
import { AppState } from '../../types/state/AppState';
import { SecurityDetails } from '../security-details';
import { pxTalkListActions, rulesActions, securityDetailsActions } from '../../actions';
import { BwicRules } from '../bwic-rules/BwicRules';
import { PxTalkList } from '../common';
import { portfolioActions } from '../../actions';
import { BwicViewLink } from '../common/BwicViewLink';
import SecurityDetailsOptions from '../security-details/SecurityDetailsOptions';
import { ControlPanelOptions } from '../bidding/common/ControlPanelOptions';
import { BWICLog } from '../security-details/bwic-log/BWICLog';
import { bwicLogActions } from '../../actions/bwic-log.actions';
import { useAppDispatch } from '../../effects/useAppDispatch';

export const PortfolioControlPanel: React.FC = () => {
    const dispatch = useAppDispatch();

    const security = useSelector((s: AppState) => s.portfolio.selectedSecurity);
    const securityDetails = useSelector((s: AppState) => s.securityDetails);
    const bwicLog = useSelector((s: AppState) => s.bwicLog);
    const selectedBwic = useSelector((s: AppState) => s.portfolio.selectedBwic);
    const pxTalkListVisible = useSelector((s: AppState) => s.pxTalkList.visible);
    const rules = useSelector((s: AppState) => s.rules);

    if (!selectedBwic) {
        return null;
    }

    const tabs = [
        {
            title: 'Px Talk',
            active: pxTalkListVisible,
            renderContent: () =>
                <PxTalkList
                    bwicReferenceName={selectedBwic.referenceName}
                    isParsed={selectedBwic.isParsed}
                />,
            onActivate: () => dispatch(pxTalkListActions.show(selectedBwic.positionId)),
            onDeactivate: () => dispatch(pxTalkListActions.hide())
        },
        {
            title: 'Security',
            active: !!securityDetails.security,
            renderContent: () => <SecurityDetails />,
            onActivate: () => dispatch(securityDetailsActions.securityDetailsInitRequest(security, true)),
            onDeactivate: () => dispatch(securityDetailsActions.securityDetailsReset())
        },
        {
            title: 'BWIC Log',
            active: !!bwicLog.security,
            renderContent: () => <BWICLog />,
            onActivate: () => dispatch(bwicLogActions.bwicLogInitRequest(security)),
            onDeactivate: () => dispatch(bwicLogActions.bwicLogReset())
        },
        {
            title: 'Rules',
            active: rules.show,
            renderContent: () =>
                <div className="overflow-auto">
                    <BwicRules
                        isParsed={selectedBwic.isParsed}
                        bidsDueDate={selectedBwic.bidsDueUtc}
                        process={selectedBwic.process}
                        parsedProcess={selectedBwic.parsedProcess}
                        goodUntilDate={selectedBwic.goodUntilUtc}
                        isSameDayBwic={selectedBwic.isSameDayBwic}
                        daysToSettle={selectedBwic.daysToSettle}
                        isMy={selectedBwic.isMy}
                        isAllToAll={selectedBwic.isAllToAll}
                        isDirectBidding={selectedBwic.isClearingBankParticipant}
                    />
                    <div className="view-bidding flex-item-right">
                        <BwicViewLink
                            referenceName={selectedBwic.referenceName}
                            status={selectedBwic.status}
                            isDirectBidding={selectedBwic.isClearingBankParticipant}
                            isAllToAll={selectedBwic.isAllToAll}
                            isMy={selectedBwic.isMy}
                            isParticipant={selectedBwic.isParticipant}
                            isParsed={selectedBwic.isParsed}
                        />
                    </div>
                </div>,
            onActivate: () => dispatch(rulesActions.show(selectedBwic.positionId)),
            onDeactivate: () => dispatch(rulesActions.hide())
        }
    ];

    return (
        <RightSideTabPanel
            className="right-panel-tabs"
            title={
                <>
                    <>{security.ticker}</>
                    <SecurityDetailsOptions optionsExclude={[ControlPanelOptions.addToPortfolio]} position={security} />
                </>
            }
            items={tabs}
            onClose={() => dispatch(portfolioActions.hideActivePanels())}
        />
    )
};
